.roadmap {
    @extend %of-hidden;

    &__item {
        padding-block: 25px;

        //use no-border class to remove the border from item
        &:not(.no-border) {
            border-bottom: 1px solid rgba($black-color, $alpha: .10);
        }
    }

    &__inner {
        @extend %flex;
        @extend %align-items-center;
        @extend %justify-between;
        gap: 40px;
        flex-direction: column;
        text-align: center;

        @include breakpoint(md) {
            flex-direction: row;
            text-align: left;
        }

    }

    &__thumb {
        @include box(150px);
        background-color: #A8D22D;
        border-radius: 50%;
        display: grid;
        place-items: center;

        //different gradient background color modifier class
        &--bg-2 {
            background: #E5A301;
        }

        &--bg-3 {
            background: #2BB3FF;
        }

        &--bg-4 {
            background: #32A55A;
        }

        &--bg-5 {
            background: #FF599E;
        }

        span {
            @include font-size(36px);
            font-weight: 700;
            color: $white-color;
        }
    }

    &__content {
        @include breakpoint(md) {
            width: calc(100% - 200px);
        }

        h4 {
            margin-bottom: 1rem;
        }

        p {
            @extend %mb-0;
            max-inline-size: 92ch;

        }
    }
}