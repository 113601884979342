.banner {
    @extend %of-hidden;
    padding-block: 75px;
    background-image: linear-gradient(262.43deg, #ED038A 0%, #FB6468 100%);

    @include breakpoint(lg) {
        padding-block: 120px;
    }
    &__content {
        @extend %mb-50;
        text-align: center;

        @include breakpoint(lg) {
            margin-bottom: 80px;
        }

        h1 {
            @extend %mb-20;
            @include font-size(65px);
            text-transform: capitalize;

            @include breakpoint(xxl) {
                max-inline-size: 90%;
                margin-left: auto;
                margin-right: auto;
            }

        }

        h1,
        p {
            color: $white-color;
        }

        p {
            @extend %mb-45;
            @include font-size(18px);
            line-height: 1.65;
        }
    }

    &__thumb {
        &-slider {
            @extend %of-hidden;
        }

        &-item {
            @extend %of-hidden;
            border-radius: 5px;

            img {
                @extend %w-100;
            }
        }
    }
}

.bg-attach--fixed {
    @include breakpoint(lg) {
        background-attachment: fixed;
    }

}